<template>
  <b-row class="match-height">
    <CreateAccount />
  </b-row>
</template>

<script>
import { BRow } from 'bootstrap-vue'
import CreateAccount from '@/components/authentication/CreateAccount.vue'

export default {
  components: {
    BRow,
    CreateAccount,
  },
  data() {
    return {}
  },
}
</script>
